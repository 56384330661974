@import "components/variables.scss";

.transfer-limit {
  padding: 8px 16px;
  display: flex;
  &__title {
    @extend .font-operations-functions;
  }
  &__text {
    @extend .font-under;
    margin-top: 4px;
    color: $color-dark-gray;
  }

  &__icon {
    $size: 24px;
    margin-top: 8px;
    width: $size;
    height: $size;
    & svg {
      max-width: 100%;
    }
  }
  &__content {
    flex-grow: 1;
  }
  &__icon + &__content {
    margin-left: 16px;
  }
}

.progress-bar {
  margin-top: 12px;
  position: relative;
  &,
  &__progress {
    width: 100%;
    height: 10px;
    background-color: rgba(#000, 5%);
    overflow: hidden;
    border-radius: 5px;
  }
  &__progress {
    position: absolute;

    top: 0;
    left: 0;
    z-index: 2;
    background-color: $color-blue;
  }
}
