@import "components/variables.scss";

// TODO -> Refactor styling

.button-icon {
  &-active {
    &-default {
      @extend .block-default;
      color: $color-black;
    }
    &-all-blue {
      background-color: $color-blue-pastelle !important;
      color: $color-blue !important;
    }
    &-bg-blue {
      @extend .block-active;
      color: $color-blue;
    }
  }
}

.button-icon {
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: none;
  color: $color-black;
  text-align: left;
  @extend .font-operations-functions;
  cursor: pointer;

  &__icon {
    $size: 24px;
    display: inline-block;
    width: $size;
    height: $size;
  }

  &-size {
    &-default {
      min-height: 64px;
      border-radius: $border-radius-default;
      @extend .block-default;
      & .button-icon__icon + .button-icon-text {
        margin-left: 16px;
      }
    }
    &-small {
      padding: 0 12px;
      min-height: 41px;
      border-radius: $border-radius-smaller;
      & .button-icon__icon + .button-icon-text {
        margin-left: 8px;
      }
      & .button-icon-text__main {
        margin-top: 0;
      }
    }
  }

  &-style {
    &-default {
      @extend .block-default;
      color: $color-black;
      &-active {
        @extend .block-active;
      }
    }

    &-lightgray {
      background-color: #f1f1f1;
      color: $color-light-gray;
    }

    &-disabled {
      // color: $color-white;
      background-color: rgba(#000, 0.7);
      border: 1px solid rgba(#000, 0.5);
      cursor: default;
    }
  }
}

.button-icon-text {
  &,
  &__main,
  &__secondary {
    display: block;
    padding: 0;
    margin: 0;
  }

  &__main {
    @extend .font-operations-functions;
    margin-top: 3px;
    color: inherit;
  }
  &__secondary {
    @extend .font-under;
    margin-top: 3px;
  }
}
