@import "components/variables.scss";

.landing {
  display: block;
}

.hero {
  &__cta {
    margin-bottom: 60px;
  }
}

.hero-text {
  display: block;
  text-align: center;
  &-h1 {
    @extend .font-hero;
    margin-bottom: 28px;
  }
  &-sub {
    @extend .font-operations-functions;
    color: $color-dark-gray;
  }
}

.hero__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-note {
  margin-top: 12px;
  @extend .font-under;
  color: $color-dark-gray;
  font-weight: 500;
}
