@import "components/variables.scss";

.currency-input {
  position: relative;
  height: 70.5px;
}

.input,
.input-copy {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  padding: 25px 16px;

  @extend .input-style;
  font-family: $font-family;

  border: none;
  border-radius: $border-radius-smaller;
}

.input-copy {
  z-index: 1;
  background-color: $color-white-almost;
  padding-top: 1.46rem;
  &__value,
  &__currency {
    display: inline-block;
    padding: 0;
    margin: 0;
  }
  &__value {
    visibility: hidden;
  }
  &__currency {
    padding-left: 3px;
    color: $color-light-gray;
  }
}
.input {
  background-color: transparent;
  z-index: 2;
}

.input {
  &::placeholder {
    color: $color-light-gray;
  }
  &:focus {
    outline: none;
  }
}
