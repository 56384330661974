@import "components/variables.scss";

$head-margin: 16px;

.history {
  display: block;
  &__head {
    margin-bottom: $head-margin;
  }

  &-reversal {
    display: flex;
    flex-direction: column-reverse;
  }
}
