@use "sass:math";
@import "components/variables.scss";

$image-circle-size: 40px;
$offset-groups: 24px;

.no-operations {
  @extend .font-input-label;
  text-align: center;
}

.operations-container {
  @extend .block-default;
  padding-left: 0;
  padding-right: 0;

  @media (max-width: $breakpoint-mobile) {
    padding: 0;
    background-color: unset;
  }

  &-blank {
    background-color: unset;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: linear-gradient(
        to top,
        $color-white,
        rgba($color-white, 0.2)
      );
    }
  }
}

.operations {
  margin-top: -$offset-groups;
}

.operations-group {
  margin-top: $offset-groups;

  &__date {
    margin: 0;
    padding: 0 16px 10px;
    @extend .font-sections;
    color: $color-dark-gray;
    @media (max-width: $breakpoint-mobile) {
      padding: 0 0 10px;
    }

    &-blank {
      width: 79px;
      height: 14px;
      background-color: $color-very-light-grayish;
      border-radius: 4px;
      padding: 0;
      margin-left: 16px;
      margin-bottom: 16px;
    }
  }
}

// Operation item
.operation {
  @extend .font-operations-functions;
  color: $color-black;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px;

  @media (max-width: $breakpoint-mobile) {
    padding: 12px 0;
  }

  &-single {
    padding: 16px;
    background-color: $color-very-light-gray-blocks;
    border-radius: $border-radius-default;
  }

  transition: background-color ease-in-out 0.1s;

  @media (min-width: $breakpoint-mobile) {
    &:hover {
      background-color: darken($color-very-light-grayish, 1%);
    }
  }

  $offset: 4px;
  &__data {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: $image-circle-size;
    margin-top: -$offset;
  }

  &__text-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: $offset;
  }

  &__text {
    margin: 0;
    padding: 0;
  }

  &__title,
  &__amt {
    @extend .font-operations-functions;
    color: $color-black;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  &__amt {
    font-weight: 400;
  }

  &__category,
  &__fee {
    @extend .font-under;
    text-transform: lowercase;
  }
}

.operation-image {
  width: $image-circle-size;
  height: $image-circle-size;
  overflow: hidden;
  border-radius: 50%;

  &,
  &__background,
  &__img {
    position: relative;
  }

  &__background {
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }

  &__img {
    $image-size: 24px;
    position: absolute;
    top: calc(50% - math.div($image-size, 2));
    left: calc(50% - math.div($image-size, 2));
    z-index: 2;
    width: $image-size;
    height: $image-size;
    & img {
      max-width: 100%;
    }
  }

  & + .operation__data {
    margin-left: 16px;
  }
}

.operation-blank {
  @extend .operation;
  $bg: $color-very-light-grayish;
  cursor: default;
  &:hover {
    background-color: inherit;
  }
  & .operation-image__background,
  & .operation__title,
  & .operation__amt,
  & .operation__category {
    border-radius: 4px;
    background-color: $bg;
    opacity: 1;
  }
  & .operation__title {
    min-width: 123px;
    height: 14px;
  }
  & .operation__amt {
    min-width: 79px;
    height: 14px;
  }
  & .operation__category {
    min-width: 57px;
    height: 10px;
    margin-top: 10px;
  }
}
// Operation popup

.operation-popup {
  margin-top: -20px;
  width: 343px;

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
  }

  &__row {
    @extend .block-default;
    @extend .shadow-black;
    margin-top: 20px;
    width: 100%;
  }
}

.info {
  $row-offset: 20px;
  margin-top: -$row-offset;

  &__row {
    margin-top: $row-offset;
  }

  &__date,
  &__amt {
    margin-bottom: 0;
  }

  &__date {
    @extend .font-operations-functions;
  }

  &__amt {
    margin-top: $row-offset + 8px;
    @extend .font-amount;
  }
}

.info-content {
  $image-size: 40px;
  display: flex;

  & .operation-image + &__data {
    margin-left: 16px;
  }

  &__data {
    flex-grow: 1;
  }
  &__owner {
    @extend .font-operations-functions;
  }
  &__mcc {
    @extend .font-under;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }
}

.location {
  display: flex;
  align-items: center;
  color: $color-dark-gray;
  line-height: 1 !important;

  &__image {
    $size: 24px;
    width: $size;
    height: $size;
    & svg {
      max-width: 100%;
    }
  }

  &__image + &__text {
    margin-left: 16px;
  }

  &__city {
    @extend .font-operations-functions;
  }
  &__location {
    @extend .font-under;
    margin-top: 4px;
  }
}

.buy-from {
  @extend .font-sections;
  color: $color-very-dark-gray;
  margin-bottom: 20px;
}
