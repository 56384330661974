@import "components/variables.scss";

.action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 8px;

  background-color: $color-blue-pastelle;
  border-radius: $border-radius-smaller;
  cursor: pointer;

  &,
  &__img svg path {
    transition: 0.2s transform ease-in-out;
  }

  &__img {
    $size: 20px;

    &,
    & > *:not(svg) {
      width: $size;
      height: $size;
      transition: 0.2s transform ease-in-out;
    }

    & svg {
      max-width: 100%;
      transform: scale(1.2);

      &,
      & path {
        fill: $color-blue;
      }
    }
  }

  &:hover {
    background-color: darken($color-blue-pastelle, 40%);
  }
}
