@import "components/variables.scss";

.card-detail-area {
  display: block;
}

.card-detail {
  @extend .block-default;
  padding: 24px 16px;
  &__pan,
  &__date,
  &__cvv {
    @extend .font-input;
    color: $color-very-dark-gray;
  }
}

.card-detail-parts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -27px;

  &__item {
    height: 25px;
    width: 45%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 27px;

    &-fullwidth {
      width: 100%;
    }
  }
}
