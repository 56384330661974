@import "components/variables.scss";

.balance {
  @extend .font-balance;
  margin-bottom: 16px;

  & span {
    &:nth-child(2),
    &:nth-child(3) {
      opacity: 0.6;
    }
  }
}
