@import "components/variables.scss";

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-row {
  display: flex;
  align-items: center;

  &__amount {
    @extend .font-operations-functions;
    display: inline-block;
    padding: 0;
    margin: 0;
    color: $color-black;
  }
}

$button-offset: 8px;
.buttons {
  margin-left: -$button-offset;
}

.button {
  display: inline-block;
  margin-left: $button-offset;
}
