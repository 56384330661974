@use "sass:math";
@import "components/variables.scss";
@import "components/generic/vendor/calendar/custom-calendar.module.scss";

$input-padding-y: 16px;
.input {
  display: block;
  width: 100%;
  padding: 25px $input-padding-y;

  background-color: $color-white-almost;
  @extend .input-style;
  font-family: $font-family;

  border: none;
  border-radius: $border-radius-smaller;
  &::placeholder {
    color: $color-light-gray;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color-blue;
  }
  &-error {
    box-shadow: 0 0 0 2px $color-red-pastelle;
  }
}

.input-clipboarder {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius-smaller;

  & .input-copy {
    position: relative;
    z-index: 1;
  }
  & .clipboarder-wrapper {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      width: 50px;
      height: 100%;
      background-color: $color-white-almost;
      box-shadow: -30px 0 15px rgba($color: $color-white-almost, $alpha: 1);
    }
  }
  & .clipboarder {
    $size: 24px;
    position: absolute;
    top: calc(50% - #{math.div($size, 2)});
    right: 16px;
    z-index: 2;
  }
}

.input-with-button {
  position: relative;
  border-radius: $border-radius-smaller;

  & .input-wrapper {
    position: relative;
    z-index: 1;
  }
  & .button-wrapper {
    &::before {
      content: "";
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: 2;
      width: 50px;
      height: calc(100% - 4px);

      border-top-right-radius: $border-radius-smaller;
      border-bottom-right-radius: $border-radius-smaller;

      background-color: $color-white-almost;
      box-shadow: -30px 0 15px rgba($color: $color-white-almost, $alpha: 1);
    }
  }

  $button-size: 24px;
  & .button,
  & .button-text {
    position: absolute;
    top: calc(50% - #{math.div($button-size, 2)});
    right: 16px;
    z-index: 2;
    height: $button-size;
    line-height: 1;
    cursor: pointer;
  }

  & .button {
    width: $button-size;
    & svg {
      width: 100%;
    }
  }

  & .button-text {
    line-height: $button-size;
  }
}

.input-date {
  position: relative;
  & input[type="date"]::-webkit-inner-spin-button,
  & input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  &__calendar {
    position: absolute;
    top: calc(50% - #{math.div($period-image-state-size, 2)});
    right: $input-padding-y;
  }
}

.textarea {
  resize: none;
}
