@import "components/variables.scss";

$back-button-size: 36px;
.back-button {
  display: inline-block;
  width: $back-button-size;
  height: $back-button-size;

  &__img {
    height: 100%;
    & svg {
      transform: rotate(-180deg);
      max-width: 100%;
      max-height: 100%;
      &,
      path {
        fill: $color-black;
      }
    }
  }
}
