@import "components/variables.scss";

.card-changer {
  display: block;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__card-logo {
    width: 91px;
    height: 24px;
    display: none;
    & svg,
    & svg path {
      fill: $color-light-gray;
      fill-opacity: 1;
    }
  }

  &-big {
    width: 220px !important;
    height: 140px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid rgba(#b8b8b8, 0.5);

    & .card__content {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      height: 100%;
      padding: 10px;
    }

    & .card-info {
      color: $color-light-gray;
      &__name {
        display: none;
      }
    }

    & .card__card-image {
      display: none;
    }
    & .card__card-logo {
      display: block;
    }
  }

  &__card-image {
    margin-right: 16px;
    width: 40px;
    height: 26px;
  }
  &__content {
    display: flex;
    align-items: center;
  }
}

.card-info {
  color: $color-dark-gray;
  @extend .font-operations-functions;
  &__name,
  &__pan {
    padding: 0;
    margin: 0;
  }
  &__pan {
    margin-top: 4px;
  }
}

.card-arrow {
  $size: 24px;

  &,
  & svg {
    height: $size;
    width: $size;
  }
}
