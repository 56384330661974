@use "sass:math";
@import "components/variables.scss";

.otp-confirm {
  display: block;
}

.under-text {
  text-align: center;
}

.otp-step {
  position: relative;
}

.otp-step__back {
  $size: 50px;

  position: absolute;
  top: calc(50% - #{math.div($size, 2)});
  left: -($size + $offset-elems-y-default);
  z-index: 2;
  transform: rotate(180deg);

  & > * {
    $size: 50px;
    width: $size;
    height: $size;
  }
}
