@import "components/variables.scss";

.react-calendar {
  @extend .block-default;
  background-color: inherit;
  font-family: $font-family;
  border: none;
}

.react-calendar button {
  border: 1px solid transparent;
  &::first-letter {
    text-transform: capitalize;
  }
  &[disabled] {
    opacity: 0.6;
    color: #757575 !important;
  }
}

.react-calendar {
  & * {
    font-size: inherit;
  }

  & abbr[title] {
    text-decoration: none;
    font-size: 11px;
    color: $color-dark-gray;
  }

  &--selectRange &__tile--hover {
    background-color: $color-blue-pastelle;
    color: $color-black;
  }

  &__navigation {
    &__prev-button,
    &__prev2-button,
    &__next-button,
    &__next2-button {
      color: $color-blue;
      font-weight: bold;
    }
  }
  &__tile {
    // each calendar day
    &--now {
      // today
      border: 1px solid $color-blue;
      background: darken($color-blue, 20%) !important;
      color: $color-white !important;
    }
    &--active {
      // selected day
      background-color: $color-blue;
      color: $color-white;
    }
    &:enabled:hover,
    &:enabled:focus,
    &:enabled:focus + &--now,
    &:enabled:hover + &--now {
      &:not(.react-calendar__year-view__months__month) {
        border: 1px solid transparent;
        background-color: $color-blue;
        color: $color-white;
      }
    }
  }
}
