@import "components/variables.scss";

.sidebar {
  position: sticky;
  top: $offset-elems-y-default;

  @media (max-width: $breakpoint-mobile) {
    position: static;
    top: unset;
  }

  &-head {
    @media (max-width: $breakpoint-mobile) {
      padding: 16px;
      background-color: $color-very-light-gray-blocks;
      border-radius: $border-radius-default;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin: 24px 0 0 -12px;

  @media (max-width: $breakpoint-mobile) {
    justify-content: flex-start;
    margin: 12px 0 0 -12px;
  }

  & > a {
    flex-grow: 1;
    margin-left: 12px;
    display: block;

    @media (max-width: $breakpoint-mobile) {
      flex-grow: unset;
    }

    & button {
      width: 100%;
      background-color: $color-very-light-gray-blocks;
      color: $color-light-gray;
      & svg,
      & svg path {
        fill: $color-light-gray;
      }
    }
    &[data-matched] button,
    &[data-active] button {
      background-color: $color-blue-pastelle;
      color: $color-blue;
      & svg,
      & svg path {
        fill: $color-blue;
      }
    }
  }
}
