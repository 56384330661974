.tabs {
  display: block;
  width: 100%;
}

.tab-list {
  display: flex;
  margin-left: -16px;
  padding-bottom: 40px;
  &__item {
    margin-left: 16px;
  }
}

.tab-panel {
  &,
  &__item {
    display: block;
  }
}
