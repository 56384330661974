@import "components/variables.scss";

$card-input-height: 72px;

.card-input {
  &-masked-pan {
    display: block;
    padding: 0;
    margin: 0;
    white-space: nowrap;
  }

  &-bank {
    $size: 40px;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $color-very-light-gray;
  }

  &-masked-pan {
    cursor: pointer;
  }
  &__expire {
    width: 70px;
  }
  &__cvc {
    width: 50px;
  }
  &__icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    & svg {
      max-width: 100%;
      &,
      & path {
        fill: $color-black;
      }
    }
  }
}

.card-input {
  position: relative;
  display: block;
  background-color: $color-white-almost;
  border-radius: $border-radius-smaller;
  overflow: hidden;
  @extend .input-style;
  & input:focus {
    outline: none;
  }

  &,
  &__part {
    height: $card-input-height;
  }

  &__part {
    display: flex;
    background-color: $color-very-light-grayish;
    &-main-data {
      position: relative;
      z-index: 1;
    }
    &-main-data,
    &-rest-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 16px 0 12px;
      &__inputs {
        display: flex;
        align-items: center;
        margin-left: -8px;
        & > * {
          margin-left: 8px;
        }
        & p,
        & input {
          padding: 0;
        }
        & input {
          padding: 5px 3px;
        }
      }
    }
    &-rest-data {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }
}
