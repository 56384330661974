@import "components/variables.scss";

.mdc-form-field {
  &,
  & *,
  & input {
    font-family: $font-family !important;
    @extend .input-style;
  }
}
.mdc-floating-label {
  color: $color-dark-gray !important;
}

.mdc-list-item {
  padding: 13px 16px !important;
}

:not(.mdc-list--non-interactive)
  > :not(.mdc-list-item--disabled).mdc-list-item__ripple::before,
:not(.mdc-list--non-interactive)
  > :not(.mdc-list-item--disabled).mdc-list-item__ripple::after {
  background-color: $color-blue !important;
}

.mdc-select {
  background-color: $color-white;
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused
  .mdc-select__dropdown-icon {
  fill: $color-blue !important;
}

.mdc-radio__outer-circle,
.mdc-radio__inner-circle {
  border-color: $color-blue !important;
}

.mdc-radio__ripple {
  display: none;
}

.mdc-list-item--selected {
  background-color: $color-blue-pastelle !important;
}
.mdc-list-item--selected .mdc-list-item__ripple {
  display: none;
}
.mdc-list-item--selected .mdc-list-item__text {
  color: $color-white !important;
}

// Text field
.mdc-text-field {
  background-color: #f1f1f1;
  &:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: $color-black;
    font-family: $font-family !important;
    @extend .input-style;
  }

  &,
  &--focused {
    & .mdc-notched-outline span[class^="mdc-notched-outline__"] {
      border-color: transparent;
    }
  }
}

.mdc-text-field--filled {
  border-radius: 4px !important;
  &,
  &:not(.mdc-text-field--disabled) {
    background-color: #f1f1f1;
  }
  & .mdc-floating-label {
    font-family: $font-family !important;
    @extend .input-style;
    color: $color-light-gray;
  }
}

/* On devices that height is less than select dropdown height,
 select dropdown opens to the top, but should open to the bottom. */
$mdc-select-height: 56px;
.mdc-select {
  height: $mdc-select-height;
}
.mdc-menu-surface {
  bottom: unset !important;
  top: $mdc-select-height + 4px !important;
}
.mdc-list {
  @media (max-width: 325px) {
    padding-bottom: 50px;
  }
}
