@use "sass:math";
@import "components/variables.scss";

$size: 130px;
$donut-middle: $size - 30px;
$stroke-width: 8;

.wrapper {
  display: block;
  @media (max-width: $breakpoint-mobile) {
    padding: 20px 0;
  }
}

.chart,
.donut {
  width: $size;
  height: $size;
}

.chart {
  position: relative;
  @media (max-width: $breakpoint-mobile) {
    margin: 0 auto 20px;
  }
}

.donut {
  transform: rotate(-90deg);

  &__ring,
  &__segment {
    fill: transparent;
    stroke-width: $stroke-width;
  }

  &__ring {
    transition: stroke-dasharray 0.3s ease;
    stroke: #d2d3d4;
  }

  &__segment {
    transition: 0.2s stroke-width ease-in-out;
    cursor: pointer;
    &:hover {
      stroke-width: $stroke-width + 2;
    }
  }

  &__hole {
    fill: $color-very-light-gray-blocks;
  }
}
.total-container {
  width: $donut-middle;
  height: $donut-middle;
  border-radius: 50%;
  background-color: $color-very-light-gray-blocks;
  position: absolute;

  top: calc(50% - #{math.div($donut-middle, 2)});
  left: calc(50% - #{math.div($donut-middle, 2)});

  display: flex;
  align-items: center;
  justify-content: center;
}

.total {
  &__title,
  &__amount,
  &__percent {
    display: block;
    text-align: center;
  }

  &__title,
  &__percent {
    @extend .font-analytics;
    font-size: 11px;
    color: $color-dark-gray;
  }

  &__amount {
    margin: 3px 0;
    font-weight: 500;
  }
}
