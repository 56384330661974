@import "components/variables.scss";

.rows {
  display: flex;
  flex-direction: column;
  margin-top: -$offset-elems-y-default;
}

.row {
  margin-top: $offset-elems-y-default;
  display: flex;
  justify-content: space-between;

  margin-left: -16px;
  & > * {
    flex-grow: 1;
    margin-left: 16px;
  }

  &-personal, &-pass {
    @media (max-width: $breakpoint-mobile) {
      flex-wrap: wrap;
      & > * {
        margin-top: 16px;
      }
    }
  }
}
