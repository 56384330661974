@import "components/variables.scss";

$items-offset: 20px;
.card-nav {
  width: 100%;
  margin-top: -$items-offset;
}

.card-nav__item {
  display: block;
  margin-top: 20px;
  & > * {
    width: 100%;
  }
}
