@import "components/variables.scss";

.profile-compact {
  display: flex;
  align-items: center;

  &-x {
    flex-direction: row;
  }
  &-y {
    flex-direction: column-reverse;
    & .userdata {
      margin: 0;
    }
    & .avatar {
      margin-bottom: 14px;
    }
    & .contacts {
      justify-content: center;
    }
  }

  &-size {
    &-default {
      display: flex;
    }
    &-big {
      display: flex;
    }
  }
}

.avatar {
  display: block;
}
.userdata {
  text-align: right;
  margin-right: 16px;
}

.username,
.contacts {
  cursor: default;
}

.username {
  margin: 0 0 8px;
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.53px;
  @extend .blue-on-hover;
}

.contacts {
  display: flex;
  justify-content: flex-end;
}

.contact {
  @extend .font-operations-functions;
  // color: $color-very-dark-gray;
  color: rgba($color-black, 65%);
}

.dot {
  margin: 0 6px;
}
