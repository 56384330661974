@import "components/variables.scss";

.errors-box {
  @extend .font-input-label;
  color: $color-red-pastelle;
  margin: 10px 0 0;
  padding: 0 16px;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
}
