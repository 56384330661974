@import "components/variables.scss";

.pretty-amount {
  font-size: inherit;

  &,
  &__rub,
  &__kop {
    display: inline-block;
    color: inherit;
  }

  &__kop,
  &__currency {
    color: $color-dark-gray;
  }

  &__currency {
    margin-left: 0.2rem;
  }
}
