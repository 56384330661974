@import "components/variables.scss";

.profile-status {
  & .text + button {
    margin-top: 40px;
  }
}
.text {
  @extend .font-input;
  color: $color-dark-gray;

  & b {
    &,
    & * {
      color: $color-blue-dark;
    }
  }
  & p:not([class]) {
    padding: 0;
    margin: 0;
  }
}
