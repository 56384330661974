@import "components/variables.scss";

.button {
  display: inline-block;
  $height: 56px;
  min-width: 170px;
  padding: 0 20px;

  background-color: $color-blue;
  color: $color-white;
  line-height: $height;
  text-align: center;
  border: none;
  @extend .font-button;
  border: 1px solid transparent;
  border-radius: $border-radius-default;
  white-space: nowrap;

  &:disabled {
    color: $color-white;
    background-color: darken($color-very-light-grayish, 4%);
    border: 1px solid darken($color-very-light-grayish, 2%);
    cursor: default;
  }

  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &-fullwidth {
    display: block;
    width: 100%;
  }

  &-blue {
    background-color: $color-blue;
    color: $color-white;
  }

  &-blue-dark {
    background-color: $color-blue-dark;
    color: $color-white;
  }

  &-gray {
    background-color: darken($color-very-light-gray, 5%);
    color: $color-white;
  }

  &-black {
    background-color: $color-black;
    color: $color-white;
  }

  &-size {
    &-default {
      min-height: 64px;
      border-radius: $border-radius-default;
      @extend .block-default;
      & .button-icon__icon + .button-icon-text {
        margin-left: 16px;
      }
    }
    &-small {
      min-width: unset;
      padding: 0 12px;
      min-height: 41px;
      line-height: 41px;
      font-size: 14px;
      border-radius: $border-radius-smaller;
      & .button-icon__icon + .button-icon-text {
        margin-left: 8px;
      }
      & .button-icon-text__main {
        margin-top: 0;
      }
    }

    &-smaller {
      min-width: unset;
      padding: 0 12px;
      height: 33px;
      line-height: 30px;
      font-size: 14px;
      border-radius: $border-radius-smaller;

      & .button-icon__icon + .button-icon-text {
        margin-left: 8px;
      }
      & .button-icon-text__main {
        margin-top: 0;
      }
    }
  }
}
