@import "components/variables.scss";

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px;
  overflow-y: scroll;

  @media (max-width: $breakpoint-mobile) {
    align-items: flex-start;
    padding: 26px 16px;
  }

  &,
  &__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: $popups-z-index;
  }
  &__overlay {
    @extend .blur;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: $breakpoint-mobile) {
      justify-content: flex-start;
    }
  }
  &__close {
    $size: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    position: relative;
    z-index: $popups-content-z-index;

    width: $size;
    height: $size;
    margin-left: 12px;

    border-radius: $border-radius-smaller;
    background-color: $color-very-light-gray-blocks;
    // border: 1px solid rgba($color-very-light-gray-blocks, 50%);
    border: 1px solid rgba($color-blue, 25%);
    @extend .shadow-black;

    & svg {
      width: 36px;
      height: 36px;
    }
    & svg,
    path {
      fill: $color-blue;
    }
  }
}

.popup {
  &-with-button {
    margin-left: -12px;
    & .content,
    .popup__close {
      margin-left: 12px;
    }

    @media (max-width: $breakpoint-mobile) {
      & .popup__content {
        flex-direction: column-reverse;
        width: 100%;
      }

      & .content {
        margin-top: 20px;
        width: 100%;
      }

      &,
      & .content,
      .popup__close {
        margin-left: 0;
      }

      & .popup__close {
        align-self: flex-end;
      }
    }
  }
}

.content {
  position: relative;
  z-index: $popups-content-z-index;
}
