@import "components/variables.scss";

$chart-mobile-height: 165px;

.bar-view {
  &,
  &__chart,
  &__legend {
    display: block;
  }

  &__chart {
    height: $chart-mobile-height;
  }
}

.donut-view {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    padding: 0;
  }

  &__chart {
    height: $chart-mobile-height;
  }

  &__chart,
  &__legend {
    display: inline-block;
    @media (max-width: $breakpoint-mobile) {
      display: block;
    }
  }

  &__legend {
    width: 73%;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }
}
