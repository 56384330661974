@import "components/variables.scss";

$bar-padding: 20px;

$legend-height: 17px;
$legend-margin: 8px;
$legend-height-total: $legend-height + $legend-margin - 1;

$bar-column-additional-height: 6px;

@mixin animation-mixin($name, $to) {
  @keyframes #{$name} {
    0% {
      height: 0;
    }
    100% {
      height: $to;
    }
  }
}

.wrapper {
  position: relative;
  padding: 20px;
}

.amount {
  & * {
    @extend .font-analytics;
    color: $color-dark-gray;
  }
  text-align: right;

  &-max,
  &-min {
    content: "";
    position: absolute;
    left: 0;
    height: $legend-height;
    width: 100%;
  }

  &-max {
    top: 3px;
  }

  &-min {
    bottom: $legend-height-total;
  }
}

.chart {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 85%;
  padding: 0 13px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
  }

  &::before {
    top: -$bar-column-additional-height;
  }

  &::after {
    bottom: $legend-height-total;
  }
}

.bars {
  display: block;
}

.bar {
  width: 32px;
}

.columns {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100px;
  position: relative;
}

.column {
  $border-radius: 2px;
  $bar-col-height: calc(var(--bar-col-height) + $bar-column-additional-height);
  position: relative;

  width: 100%;
  cursor: pointer;

  transition: height ease-in-out 0.2s;
  animation: 0.2s animate-height ease-in-out 0.2s forwards;
  @include animation-mixin(animate-height, $bar-col-height);

  &:hover {
    height: calc($bar-col-height + 4px);
    & .tooltip {
      display: block;
    }
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.tooltip {
  $tooltip-width: 100px;

  @extend .block-default;
  @extend .shadow-black;
  @extend .font-analytics;

  padding: 5px 10px;
  display: none;
  position: absolute;
  z-index: 100;
  top: 0;
  right: -$tooltip-width - 10px;

  width: $tooltip-width;

  &__name {
    width: calc($tooltip-width - (10px * 2));
    height: $legend-height;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__name,
  &__amount {
    display: block;
  }
}

.legend {
  @extend .font-analytics;

  display: block;
  height: $legend-height;
  margin-top: $legend-margin;
  color: $color-dark-gray;
  text-align: center;
  white-space: nowrap;
}
