@use "sass:math";

@use "./variables.scss";
@use "@material/theme" with (
  $primary: variables.$color-black,
  $secondary: variables.$color-very-dark-gray
);
@use "components/generic/vendor/material/radio/radio.scss";
@use "components/generic/vendor/material/select/select.scss";
@use "components/generic/vendor/material/text-field/textfield.scss";
@use "components/generic/vendor/material/reset.scss";

@use "components/generic/vendor/calendar/calendar.scss";

@use "components/generic/vendor/swiper/swiper.scss";
@use "components/generic/vendor/input-phone/input-phone.scss";

@import "./variables.scss";

html,
body,
button {
  font-family: $font-family;
  line-height: 1.4;
  color: $color-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: $color-white;
  * {
    box-sizing: border-box;
    caret-color: $color-very-dark-gray !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  &:visited {
    color: inherit;
  }
}

.container {
  // min-width: #{$container-width} + "px";
  // max-width: #{$container-width} + "px";
  // width: #{$container-width-desktop} + "%";

  width: #{$container-width} + "px";
  margin: 0 auto;

  @media (max-width: $breakpoint-mobile) {
    width: #{$container-width-mobile} + "%";
  }
}

.inblock-popup > * {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  min-height: 100%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-left: -$offset-elems-x-default;
  & .button {
    margin-left: $offset-elems-x-default;
    flex-grow: 1;
  }
}

.rows {
  margin-top: -$offset-blocks-y-default;
}

.row {
  margin-top: $offset-blocks-y-default;
  &__item {
    margin-top: 28px;
  }
}

.pointer {
  cursor: pointer;
}

@keyframes appear-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.appear {
  opacity: 0;
  animation: .3s appear-opacity 0.5s ease-in forwards;
}

.small-note {
  @extend .font-input-label;
  color: $color-dark-gray;
  margin: 10px 0 0;
  padding: 0 16px;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
  & a {
    text-transform: none;
  }
}

.popup-icon {
  display: inline-block;
  $size: 36px;
  width: $size;
  height: $size;
  & svg,
  & img {
    max-width: 100%;
  }
}

.popup-icon + .popup-text {
  margin-top: $offset-elems-y-default;
}

.popup-text {
  @extend .font-popup;
}

.color-blue {
  color: $color-blue !important;
}

.color-dark-gray {
  color: $color-dark-gray !important;
}

.color-very-dark-gray {
  color: $color-very-dark-gray !important;
}

.page-title {
  @extend .font-headings;
  margin-bottom: 40px;
}

.page-content {
  &-mv-restricted {
    width: 448px;
    margin: 0 auto;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
  }
}

.text-under-button {
  @extend .font-button-label;
  padding: 0;
  margin: 32px 0 0 0;
  color: $color-dark-gray;
}

hr {
  margin: $offset-elems-y-default 0;
  border: 1px solid $color-very-light-gray;
  border-bottom-width: 0;
}

.mobile-hidden {
  @media (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.desktop-hidden {
  //@media (min-width: $breakpoint-mobile) {
  //  display: none;
  //}
  display: none;
}
