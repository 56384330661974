@import "components/variables.scss";

@mixin circle-size($size) {
  width: $size;
  height: $size;
}

.avatar-wrapper,
.avatar {
  border-radius: 50%;
  overflow: hidden;
}

.avatar-wrapper {
  &,
  &-medium {
    $wrapper-size: 60px;
    $wrapper-size-mobile: 44px;

    @include circle-size($wrapper-size);
    & .avatar__emoji {
      font-size: 30px;
    }

    @media (max-width: $breakpoint-mobile) {
      @include circle-size($wrapper-size-mobile);
      & .avatar__emoji {
        font-size: 26px;
      }
    }
  }
  &-big {
    $wrapper-size: 86px;
    @include circle-size($wrapper-size);

    & .avatar__emoji {
      font-size: 54px;
    }
  }

  $avatar-size: 90%;
  & .avatar {
    width: $avatar-size;
    height: $avatar-size;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.avatar-wrapper {
  border: 3px solid $color-very-light-gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  &-fullwidth {
    width: 100% !important;
    height: 100% !important;
  }

  &__img,
  &__img img {
    width: 100%;
    height: 100%;
  }

  &__emoji {
    display: inline-block;
  }
}
