@use "sass:math";
@import "components/variables.scss";

$legend-offset: 9px;
$legend-padding: 4px 8px;

$active-indicator-width: 36px;

.legend {
  margin-top: -$legend-offset;
  margin-left: -$legend-offset;
}

.legend-item {
  $bgc: var(--bgc);
  $bgc-default: #ededed;

  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  padding: $legend-padding;
  margin-top: $legend-offset;
  margin-left: $legend-offset;
  border-radius: 7px;

  cursor: pointer;

  background-color: $bgc;

  border: 1px solid transparent;

  &-disabled {
    transition: 0.3s background-color ease-in-out 0.1s;
    background-color: $bgc-default;
    border: 1px solid $bgc;
  }

  &__name,
  &__amt {
    display: inline-block;
    @extend .font-analytics;
    &,
    & * {
      color: transparent !important;
    }

    background: inherit;
    background-clip: text;
    -webkit-background-clip: text;
    filter: invert(1) grayscale(1) contrast(10);
  }

  &__amt {
    font-weight: 600;
    margin-left: 6px;
  }

  & * {
    transition: 0.3s all ease-in-out;
  }

  @media (min-width: $breakpoint-tablet-small) {
    &:hover {
      & .active-indicator {
        right: 0;
      }
      & .legend-item__amt {
        opacity: 0;
      }
    }
  }

  & .active-indicator {
    position: absolute;
    top: 0;
    right: -$active-indicator-width;
    background-color: $bgc;
    @media (max-width: $breakpoint-mobile) {
      right: 0;
    }
  }
}

.active-indicator {
  display: flex;
  align-items: center;
  justify-content: center;

  width: $active-indicator-width;
  height: 100%;

  @media (max-width: $breakpoint-mobile) {
    width: 90%;
    background-color: transparent !important;

    & * {
      display: none;
    }
  }

  position: relative;
  &__bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    filter: brightness(85%);
  }

  &__img {
    position: relative;
    z-index: 2;
    display: inline-flex;
    $size: 20px;
    & svg {
      width: $size;
      height: $size;
      & path {
        fill: #fff;
        fill-opacity: 1;
      }
    }
  }
}
