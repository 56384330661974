@import "components/variables.scss";

.swiper-own {
  $bullet-size: 7px;

  padding: 0 0 27px 0;
  & .swiper-pagination {
    bottom: 0;
    &-bullet {
      width: $bullet-size;
      height: $bullet-size;
      opacity: 1;
      background: $color-very-light-gray;
      &-active {
        background: $color-blue;
      }
    }
  }
  &-last-bullet-plus .swiper-pagination-bullet {
    &:last-child {
      background: none;
      position: relative;
      width: $bullet-size;
      height: $bullet-size;

      &::after,
      &::before {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        width: $bullet-size;
        border-radius: 1px;
        height: 2px;
        background: $color-very-light-gray;
      }
      &::after {
        transform: rotate(90deg);
      }
    }
    &-active:last-child {
      &::after,
      &::before {
        background: $color-blue;
      }
    }
  }
}
