@use "sass:math";

// Mixins
@function percent($element, $parent-element) {
  @return #{math.div($element, $parent-element) * 100} + "%";
}

$color-white: #fff;
$color-white-almost: #f1f1f1;
$color-blue: #6ed3fc;
$color-blue-dark: #6688ff;
$color-blue-pastelle: rgba($color-blue, 0.1);
$color-red-pastelle: #f07878;

$color-black: #000;
$color-very-dark-gray: #595959; // 65%
$color-dark-gray: #8c8c8c; // 45%
$color-light-gray: #cccccc; // 20%
$color-very-light-gray-blocks: #f8f8f8;
$color-very-light-gray: #e5e5e5; // 10%
$color-very-light-grayish: #f2f2f2;

$border-radius-default: 12px;
$border-radius-smaller: 7px;

$container-width: 920;
$container-width-desktop: math.div($container-width, 1440) * 100;
$container-width-mobile: math.div(343, 375) * 100;
$sidebar-width: percent(330, $container-width);
$main-area-width: percent(566, $container-width);
$main-area-width-mobile: 100%;

$popups-z-index: 99999;
$popups-content-z-index: $popups-z-index + 1000;

$offset-elems-y-default: 20px;
$offset-elems-x-default: 16px;
$offset-blocks-y-default: 40px;

// Responsive
$breakpoint-tablet-small: 650px;
$breakpoint-mobile: 430px;

.font-headings {
  // заголовки экранов
  // fw: bold; lh: 41; tr: -0.53px;
  padding: 0;
  margin: 0;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.53px;
}

.font-sections {
  // названия разделов функционала, кнопка справа у разделов
  // fw: medium; lh: auto; tr: -0.09px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.09px;
}

.font-hero {
  padding: 0;
  margin: 0;
  font-size: 52px;
  font-weight: 700;
  letter-spacing: -0.53px;
}

.font-balance {
  // сумма баланса
  // fw: medium; lh: auto; tr: -0.69px;
  padding: 0;
  margin: 0;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -1.07px;
}

.font-amount {
  // сумма в счете или транзакции
  // fw: semi-bold; lh: auto; tr: -0.69px;
  padding: 0;
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.69px;
}

.font-operations-functions {
  // Операции или транзакции, категории, иконки к второстепенным экранам и функционалу
  // fs: 16px; fw: medium; lh: auto; tr: -0.18px;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 1.188;
  // color: $color-very-dark-gray;
}

.font-under {
  // Подпись к операциям и транзакциям, время, название категории, номер карты, подпись к Персональным данным
  // fw: regular; lh: auto; tr: -0.08px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.08px;
  color: $color-dark-gray;
}

.font-analytics {
  // (используется в аналитике)
  // fw: regular; lh: auto; tr: 0.05px;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05px;
  color: $color-black;
}

.input-style {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.05px;
  color: $color-black;
}

.font-button {
  // fw: semi-bold; lh: 18; tr: -0.04px;
  // текст в кнопке
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.04px;
}

.font-button-label {
  // информативная подпись под кнопками
  // fw: regular; lh: 20; tr: -0.13px;

  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.13px;
}

.font-input {
  //  текст для ввода пользователем, активный и неактивный, текст с Персональными данными
  // fw: regular; lh: 22; tr: -0.22px;
  padding: 0;
  margin: 0;
  @extend .input-style;
}

.font-input-label {
  // используется для подписи к полям ввода текста
  // fw: regular; lh: 18; tr: -0.04px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.04px;
  color: $color-dark-gray;
}

.font-popup {
  // в надписях попапов
  // fw: medium; lh: 22; tr: -0.22px;
  padding: 0;
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.22px;
}

$font-name-right: 16px; // fw: regular; lh: auto; tr: -0.18px; (подпись к операции справа, cумма)

$font-input-label-active: 13px; // fw: semi-bold; lh: 18; tr: -0.04px; (используется для подписи к полям ввода текста)

$font-button-label-interactive: 15px; // fw: semi-bold; lh: 20; tr: -0.13px; (шрифт подписи к кнопке, по которой можно перейти на другую страницу)

$font-family: "Inter", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

$shadow-default: 0 0 10px rgba(110, 211, 252, 25%);
$shadow-black: 0 0 25px rgba(0, 0, 0, 10%);

$header-height: 150px;
$header-height-mobile: 76px;
$footer-height: 30px;

.blue-on-hover {
  transition: 0.3s color ease-in-out;
  &:hover {
    color: darken($color-blue, 20%);
  }
}

.block-default {
  // Плашки
  display: block;
  padding: 16px;
  background-color: $color-very-light-gray-blocks;
  border-radius: $border-radius-default;
}

.block-active {
  // Плашки активные
  background-color: $color-blue-pastelle;
  border-radius: 12px;
}

.blur {
  background: rgba($color-white, 0.4);
  backdrop-filter: blur(8px);
}

.shadow-default {
  box-shadow: $shadow-default;
}

.shadow-black {
  box-shadow: $shadow-black;
}

.no-scrollbar {
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}

.hero-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12vh;
  min-height: calc(100vh - $header-height);
}

// @function offset($element-width, $elements-total) {
//   @return math.div(
//     100% - $element-width * $elements-total,
//     $elements-total - 1
//   );
// }

// @function offset-exact-total($element-width, $elements-total) {
//   @return math.div(100% - $element-width * $elements-total, $elements-total);
// }
