@import "components/variables.scss";

.operations {
  display: block;
  margin-top: -20px;
  // height: 100%;

  &__row {
    margin-top: 20px;
  }

  &__row:last-child {
    margin-top: 35px;
  }

  &__fee {
    @extend .font-input-label;
    color: $color-dark-gray;
    margin-top: 10px;
    padding: 0 16px;
  }
}
