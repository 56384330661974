@import "components/variables.scss";

$calendar-z-index: 5000;
$period-image-state-size: 20px;

.controls {
  margin: 0 0 0 -8px;
  display: flex;

  &-text {
    margin-left: -3px;

    & .period,
    & .control {
      margin-left: 3px;
      // outline: 1px solid;
    }

    & .period {
      color: $color-blue;
      // color: $color-dark-gray;
      padding: 0;
      & .period__text {
        margin-left: 0;
      }
      & .period__img {
        display: none;
      }
    }

    & .control {
      background-color: unset;
      padding: 0;

      & svg,
      & svg path {
        fill: $color-blue;
      }
    }

    & .control-disabled {
      & svg,
      & svg path {
        fill: $color-light-gray;
      }
    }
  }

  &-icon {
    & .control {
      display: none;
    }
    & .period {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $period-image-state-size;
      height: $period-image-state-size;
      padding: 0;
      margin-left: 0;
      background-color: unset;
    }
    & .period__text {
      display: none;
    }
    & .period__img {
      width: $period-image-state-size;
      height: $period-image-state-size;
    }
  }
}

$period-color: rgba($color-black, 20%);

.period,
.control {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  color: $period-color;
  border-radius: $border-radius-smaller;
  background-color: $color-very-light-gray-blocks;
  cursor: pointer;
}

.period {
  padding: 5px 12px;
  &__img {
    $size: 15px;
    display: inline-block;
    width: $size;
    height: $size;

    & svg {
      max-width: 100%;
    }
    & svg,
    & path {
      fill: $period-color;
    }
  }
  &__img + &__text {
    margin-left: 8px;
  }
}

.control {
  cursor: pointer;
  background-color: $color-blue-pastelle;
  padding: 5px 8px;

  &-disabled {
    background-color: $color-very-light-gray-blocks;
    cursor: default;
  }

  $period-image-size: 20px;

  &-back,
  &-forward {
    & .control__img {
      width: $period-image-size;
      height: $period-image-size;
      & svg {
        max-width: 100%;
      }
    }
  }

  &-back {
    svg {
      // max-width: 100%;
      transform: rotate(180deg);
    }
  }
}

.calendar-wrapper {
  position: relative;
}

.calendar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $calendar-z-index;

  @extend .block-default;
  padding: 0;
  background-color: $color-very-light-gray-blocks;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);

  font-size: 15px;

  &-icon {
    left: unset;
    right: 0;
    top: 16px;
  }

  &__close {
    padding: 10px 0;
    text-align: center;
    border-top: 1px solid darken($color-very-light-gray-blocks, 10%);

    & span {
      display: inline-block;
      padding: 10px;
      cursor: pointer;
      transition: 0.2s color ease-in-out;
      &:hover {
        color: $color-blue;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $calendar-z-index - 1;
  width: 100vw;
  height: 100vh;
}
