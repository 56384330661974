@use "sass:math";
@import "components/variables.scss";

.analytics {
  @extend .block-default;
}

.diff {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.diff {
  @extend .font-analytics;
  opacity: 0.6;
  display: inline-flex;
  margin-left: 10px;
  align-items: center;
  padding: 3px 8px;
  border-radius: $border-radius-smaller;
  background-color: $color-very-light-gray;
  cursor: pointer;

  &__img {
    margin-left: 5px;
    $size: 15px;
    display: inline-block;
    height: $size;
    width: $size;
    svg {
      max-width: 100%;
    }
  }
}

.total-text {
  @extend .font-analytics;
  color: $color-very-dark-gray;
  margin-top: 8px;
}
