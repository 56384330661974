@import "components/variables.scss";

.phone-list {
  display: block;
  padding: 0 0 20px;
  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.selection {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @extend .block-default;
  padding: 0;

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.suggested-area-wrapper {
  overflow: hidden;
}

.suggested-area {
  margin-left: -25px;
  margin-top: -25px;

  &-horizotal-scroll-mobile {
    @extend .no-scrollbar;

    @media (max-width: $breakpoint-mobile) {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      overflow-x: scroll;
    }
  }

  &-vertical {
    margin: 20px 0 0 0;
    padding-top: 0;
    height: 430px;
    @extend .no-scrollbar;

    overflow: hidden;
    overflow-y: scroll;
    border-top: 1px solid $color-very-light-gray;

    & .suggested {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 16px 0 0;

      &__name {
        @extend .font-operations-functions;
        font-weight: 400;
        margin: 0 0 0 16px;
        max-width: unset;
      }
    }
  }
}

.suggested {
  $size: 56px;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  margin-top: 25px;
  cursor: pointer;

  &__img {
    $size: 56px;
    width: $size;
    height: $size;
    border-radius: 50%;
    overflow: hidden;
    & img {
      max-width: 100%;
    }
  }

  &__name {
    padding: 0;
    margin: 8px 0 0;

    @extend .font-analytics;
    color: $color-black;

    max-width: 75px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.not-found {
  @extend .font-operations-functions;
  color: $color-very-dark-gray;
  text-align: center;
  margin: 20px 0;
  font-weight: 400;
}
