@use "sass:math";
@import "components/variables.scss";
@import "components/generic/button/back-button/back-button.module.scss";

.layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-mh {
    min-height: calc(100vh - #{$header-height + $footer-height});
  }
  
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.aside {
  width: $sidebar-width;

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    padding: 28px 0;
  }
}

.main {
  width: $main-area-width;
  @media (max-width: $breakpoint-mobile) {
    width: $main-area-width-mobile;
  }
}

.closeable-block {
  position: relative;
  @extend .block-default;
  background-color: #fff;

  @media (max-width: $breakpoint-mobile) {
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    padding: 0;
    &__head,
    &__content {
      @media (max-width: $breakpoint-mobile) {
        width: #{$container-width-mobile} + "%";
        margin: 0 auto;
      }
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 7px;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column-reverse;
    }
  }
  &__title,
  &-nav-link {
    @extend .font-sections;
  }
  &__title {
    color: $color-black;

    @media (max-width: $breakpoint-mobile) {
      font-size: 26px;
      font-weight: bold;
    }

    &-dark-gray {
      color: $color-very-dark-gray;
    }
  }

  &-nav-link {
    cursor: pointer;
    color: $color-blue !important;
    &__text {
      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
    &__icon {
      height: $header-height-mobile;
      & > * {
        position: relative;
        top: calc(50% - #{math.div($back-button-size, 2)});
        left: 0;
      }

      @media (min-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }

  &__head + &__content {
    padding: 28px 0 12px 0;
    height: 100%;
  }

  &__content {
    max-width: 446px;
    margin: 0 auto;
    padding: 0;
  }
}

.label-panel {
  display: flex;
  justify-content: space-between;
  @extend .font-sections;
  padding-bottom: 16px;

  &__title,
  &__button {
    padding: 0;
    margin: 0;
  }
  &__button {
    color: $color-blue;
    cursor: pointer;
  }

  &,
  &-black {
    color: $color-black;
  }

  &-dark-gray {
    color: $color-very-dark-gray;
  }
}

.list {
  display: block;
  list-style: none;

  margin: -$offset-elems-y-default 0 0 0;
  padding: 0;
}

.list-item {
  margin: $offset-elems-y-default 0 0 0;
  padding: 8px 0;

  &,
  &__content {
    display: flex;
    align-items: center;
  }
  justify-content: space-between;

  &__icon {
    display: inline-block;
    $size: 24px;
    width: $size;
    height: $size;
    & img,
    & svg {
      max-width: 100%;
    }
    & svg,
    & svg path {
      transition: 0.2s fill ease-in-out;
    }
  }

  &__text {
    margin: 0 0 0 $offset-elems-x-default;
    padding: 0;
    @extend .font-operations-functions;
  }

  &:hover {
    & .list-item__icon-arrow svg,
    & .list-item__icon-arrow svg path {
      fill: $color-blue;
    }
  }
}
