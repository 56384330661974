@import "components/variables.scss";

$offset: 17px;

.delimiter {
  display: block;
  width: 1px;
  min-height: 100%;

  background-color: $color-very-light-gray;

  @media (max-width: $breakpoint-mobile) {
    height: 1px;
    width: 100%;
    margin: 20px 0;
  }
}

.presets-box {
  display: block;
}

.avatar-changer {
  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.current {
  display: inline-block;
  position: relative;

  &__avatar {
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  &__delete {
    $size: 19px;

    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    width: $size;
    height: $size;
    cursor: pointer;

    background-color: $color-blue;
    border-radius: 50%;
    & svg {
      width: 80%;
      &,
      & path {
        fill: $color-white;
      }
    }
  }
}

.constructor {
  width: 80%;

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.presets {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$offset;
  margin-top: -$offset;

  @media (max-width: $breakpoint-mobile) {
    margin: 0;
    flex-wrap: nowrap;
    justify-content: unset;

    display: grid;
    grid-template-columns: repeat(4, 2fr);
    grid-template-rows: repeat(4, 2fr);
    gap: 10px;
  }
}

.preset {
  margin-left: $offset;
  margin-top: $offset;
  &,
  & * {
    cursor: pointer;
  }

  @media (max-width: $breakpoint-mobile) {
    & {
      margin: 0;
      justify-self: center;

      // padding-bottom: 24.9%;
    }
  }

  &-avatar {
    @media (max-width: $breakpoint-mobile) {
      $size: 72px;

      &,
      & > * {
        width: $size;
        height: $size;
      }
      & * {
        font-size: 40px !important;
      }
    }
  }
}

.btn {
  @extend .font-sections;
  padding: 8px 12px;

  cursor: pointer;
  background-color: $color-white-almost;
  border-radius: $border-radius-smaller;

  &-active {
    background-color: $color-blue;
    color: $color-white;
  }
}

.button-confirm {
  margin-top: 40px;
  text-align: center;
  & > * {
    display: inline-block;
    width: 90%;
  }
}
