@import "components/variables.scss";

.drawers {
  margin-top: -20px;
  & .drawer {
    margin-top: 20px;
    &:last-child .drawer-content {
      padding-bottom: 0;
    }
  }
}

.drawer {
  display: block;
}

.drawer-head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    @extend .font-operations-functions;
    font-weight: 400;
  }
  &__icon {
    display: inline-block;
    $size: 24px;
    width: $size;
    height: $size;
    transform: rotate(90deg);
    transition: 0.3s transform ease-in-out;

    &-upside-down {
      transform: rotate(-90deg);
    }

    & img,
    & svg {
      max-width: 100%;
    }
    & svg,
    & svg path {
      transition: 0.2s fill ease-in-out;
    }
  }
}

.drawer-head + .drawer-content {
  padding: 12px 0 16px 0;
}
